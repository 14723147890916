import util from '@bmp-vue/core/api/util'

const controller = 'Rrc0019';
export default Object.assign({}, util.GetCrud(controller, ['']), {
    Incluir: function (dto) {
        return util.Axios.Post(
            controller + '/operacao',
            dto
        );
    },
    IncluirRegistrosEmLote: function (dto) {
        return util.Axios.Post(
            controller + '/operacao/ImportarLote',
            dto
        );
    },
    AlterarRegistroEmLote: function (identOp, dto) {
        return util.Axios.Put(
            controller + `/operacao/ImportarLote`, identOp,
            dto
        );
    },
    GetAllTitularesByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/titulares`)
    },
    GetAllConjuntosDomiciliosByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/ConjuntosDomicilios`)
    },
    GetAllRenegociacoesDividasByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/RenegociacoesDividas`)
    },
    GetAllCessionariosAutorizadosByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/CessionariosAutorizados`)
    },
    GetRespostaRegistroByCodigo: function(codigo) {
        return util.Axios.GetAll(controller + `/${codigo}/RespostaRegistro`)
    }
});  
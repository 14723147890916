import util from '@bmp-vue/core/api/util'

const controller = 'Requisicao';
export default Object.assign({}, util.GetCrud(controller, ['getall']), {
    GetAllRrc0019ByCodigoRequisicao: function(codigoRequisicao) {
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/Rrc0019')
    },
    GetRespostaSincronaByCodigo: function(codigoRequisicao) {
        return util.Axios.GetAll(controller + '/' + codigoRequisicao + '/RespostaSincrona')
    },
});  